// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  companyName: 'DIP',
  companyDescription: 'Trung tâm Nghiên cứu, Đào tạo & Phát triển phần mềm DIP Vietnam - Tầng 9, Tòa nhà VCN Tower, 02 Đường Tố Hữu, Phường Phước Hải, TP Nha Trang, Tỉnh Khánh Hòa',
  companyHotline: '(028) 710 66 777',
  companyFax: '(028) 710 66 777',
  companyMail: 'sales@dip.vn',
  // apiUrl: 'https://localhost:44311',
  apiUrl: 'https://apiwebdb.dip.vn',
  formatDateTimeTable: '',
  formatDateTable: '',
  gMapKey: '',
  eyeShow: '',
  eyeHide: '',
  passwordToken: '',
  userNameToken: '',
};
environment.formatDateTimeTable = 'yyyy/MM/dd HH:mm';
environment.formatDateTable = 'yyyy/MM/dd';
environment.gMapKey = 'AIzaSyBu4Y90CByGCKL5rxjR1bfCPAKVkh5KhYA';
environment.eyeShow = 'assets/layout/images/icon-pasword/eye-icon.svg';
environment.eyeHide = 'assets/layout/images/icon-pasword/eye-hidden-icon.svg';
environment.passwordToken = "DIP20221@3";
environment.userNameToken = "userDIPPro";
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
