import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page403',
  templateUrl: './page403.component.html',
  styles: [
  ]
})
export class Page403Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
