import { HandleError } from '../../infrastructure/handleError';
import { MsgService } from '../local/msg.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ResponseModel } from '../../models/models/response.model';

// @Injectable()
export class BaseService extends HandleError {
    constructor(
        protected messageService: MsgService,
        protected httpClient: HttpClient,
        protected urlName: string,
        protected apiName: string
    ) {
        super(messageService);
    }

    async getCustomApi(apiMethod: string, params: HttpParams): Promise<ResponseModel> {
        return await this.httpClient.get<ResponseModel>(`${this.urlName}/${this.apiName}/${apiMethod}`, { params }).toPromise();
    }

    async postCustomApi(apiMethod: string, model: object): Promise<ResponseModel> {
        return await this.httpClient.post<ResponseModel>(`${this.urlName}/${this.apiName}/${apiMethod}`, model).toPromise();
    }

    async postCustomApiHeader(apiMethod: string,model: HttpParams): Promise<ResponseModel> {
        let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        return await this.httpClient.post<ResponseModel>(`${this.urlName}/${apiMethod}`, model, {headers: headers}).toPromise();
    }
}
