import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Page403Component } from './shared/components/page403/page403.component';
import { Page404Component } from './shared/components/page404/page404.component';
import { ButtonModule } from 'primeng/button';
import { ApiEndpointInterceptor } from './shared/http-interceptor/api-endpoint.interceptor';
import { FormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import { AutoCompleteModule, DialogModule, DynamicDialogModule, FileUploadModule, GalleriaModule, InputNumberModule, OverlayPanelModule, TooltipModule } from 'primeng';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmCoreConfig } from './shared/infrastructure/agmCore.config';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    Page403Component,
    Page404Component,
    AppComponent,
  ],
  imports: [
    AgmCoreModule.forRoot(AgmCoreConfig),
    HttpClientModule,
    ToastModule,
    ButtonModule,
    NgProgressModule,
    NgProgressHttpModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DropdownModule,
    DynamicDialogModule,
    DialogModule,
    InputNumberModule,
    AutoCompleteModule,
    GalleriaModule,
    TooltipModule,
    OverlayPanelModule,
    FileUploadModule,
  ],
  providers: [
    GoogleMapsAPIWrapper,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiEndpointInterceptor, multi: true},
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
