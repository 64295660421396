export const Constant = {
    messageStatus: {
        success: 'success',
        info: 'info',
        warn: 'warn',
        error: 'warn',
    },
    response: {
        isSuccess: 'isSuccess',
        message: 'message',
        data: 'data',
        exception: 'exception',
    },
    auths: {
        isLoginIn: 'loggedIn',
        token: 'token',
        customerID: 'customerID',
        customerCode: 'customerCode',
        lastName: 'lastName',
        firstName: 'firstName',
        projectID: 'projectID',
        tokenFirebase: 'tokenFirebase',
        datetimeLogin: 'datetimeLogin',
        expires: 'expires',
        maMB: 'maMB',
        dienThoaiKH: 'dienThoaiKH',
        NewId: 'NewId',
        buildingName: 'buildingName',
        buildingDescription: 'buildingDescription',
        buildingFullName: 'buildingFullName',
        buildingPhone: 'buildingPhone',
        buildingAddress: 'buildingAddress',
        buildingFax: 'buildingFax',
        buildingEmail: 'buildingEmail',
        companyName:  'companyName'
    }
};
