import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MsgService } from '../local/msg.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../local/storage.service';
import { Router } from '@angular/router';
import { ResponseModel } from '../../models/models/response.model';
import { LoginModel } from '../../models/models/login.model';
import { Constant } from '../../infrastructure/constant';
import { GeneralService } from './general.service';
import { RegisterModel } from '../../models/models/register.model';
import { ChangePasswordRequetModel } from '../../models/models/changePasswordRequet.model';
import { CustomerInfoLogModel } from '../../models/models/customerInfoLog.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends GeneralService {

  constructor(
    protected messageService: MsgService,
    protected httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router
  ) {
    super(messageService, httpClient, environment.apiUrl, 'api/LoginCustomer');
  }

  async login(model: LoginModel): Promise<ResponseModel> {
    const res = await this.postCustomApi('LoginCustomer', model);
    if (res.Status === "OK") {
      if (true) {
        let obj: CustomerInfoLogModel = JSON.parse(res.Data);
        console.log("obj", obj)
        this.storageService.set(Constant.auths.isLoginIn, 'true');
        this.storageService.set(Constant.auths.customerID, obj ? obj[0]?.CustomerID?.toString(): "");
        this.storageService.set(Constant.auths.customerCode, obj ? obj[0]?.CustomerCode: "");
        this.storageService.set(Constant.auths.lastName, obj ? obj[0]?.LastName: "");
        this.storageService.set(Constant.auths.firstName, obj ? obj[0]?.FirstName: "");
        this.storageService.set(Constant.auths.projectID, obj ? obj[0]?.ProjectID?.toString(): "");
        this.storageService.set(Constant.auths.maMB, obj ? obj[0]?.TN[0]?.MB[0]?.MaMB?.toString(): "");
        this.storageService.set(Constant.auths.dienThoaiKH, obj ? obj[0]?.DienThoaiKH: "");
        this.storageService.set(Constant.auths.buildingName, obj ? obj[0]?.TN[0]?.TenVT?.toString() : "");
        this.storageService.set(Constant.auths.buildingDescription, obj ? obj[0]?.TN[0]?.Description?.toString(): "");
        this.storageService.set(Constant.auths.buildingFullName, obj ? obj[0]?.TN[0]?.TenTN?.toString(): "");
        this.storageService.set(Constant.auths.buildingPhone, obj ? obj[0]?.TN[0]?.DienThoai?.toString(): "");
        this.storageService.set(Constant.auths.buildingAddress, obj ? obj[0]?.TN[0]?.DiaChi?.toString(): "");
        this.storageService.set(Constant.auths.buildingFax, obj ? obj[0]?.TN[0]?.Fax?.toString(): "");
        this.storageService.set(Constant.auths.buildingEmail, obj ? obj[0]?.TN[0]?.Email?.toString(): "");
        this.storageService.set(Constant.auths.companyName, obj ? obj[0]?.CtyTenVT?.toString(): "");

      }
    } else {
      this.storageService.removeAll();
    }
    return res;
  }

  async getToken(userName: string, password: string, grantType: string): Promise<ResponseModel> {
    let params = new HttpParams();
    params = params.append('userName', userName);
    params = params.append('password', password);
    params = params.append('grant_type', grantType);

    const res = await this.postCustomApiHeader('Token', params);

    if (res.access_token != null && res.access_token.length > 0) {
      this.storageService.set(Constant.auths.isLoginIn, 'true');
      this.storageService.set(Constant.auths.token, res.access_token);
    }
    return res
  }

  async logout() {
    this.storageService.removeAll();
    this.router.navigate(['/']);
  }

  isLogged() {
    const token = this.storageService.get(Constant.auths.token);
    if (token) {
      return true;
    }
    return false;
  }

  async changePassWord(model: ChangePasswordRequetModel) {
    model.customerId = parseInt(this.storageService.get(Constant.auths.customerID));
    const res = await this.postCustomApi('ChangePassword', model);

    if (!this.isValidResponseStatus(res[0])) { return; }
    return res[0];
  }

  async checkEmail(email: any): Promise<ResponseModel> {
    let params = new HttpParams();
    params = params.append('email', email);
    const res = await this.getCustomApi('CheckEmail', params);
    return res;
  }

  async checkNumberPhone(numberPhone: any): Promise<ResponseModel> {
    let params = new HttpParams();
    params = params.append('numberPhone', numberPhone);
    const res = await this.getCustomApi('CheckNumberPhone', params);
    return res;
  }

  getUserId(): string {
    return this.storageService.get(Constant.auths.customerID);
  }

  getUserName(): string {
    return this.storageService.get(Constant.auths.customerCode);
  }

  getFullName(): string {
    return this.storageService.get(Constant.auths.firstName);
  }

  getPhone(): string {
    return this.storageService.get(Constant.auths.dienThoaiKH);
  }

  getCompanyName(): string {
    return this.storageService.get(Constant.auths.companyName);
  }

  async forgotPassword(mailTo: string): Promise<ResponseModel> {
    let params = new HttpParams();
    params = params.append('mailTo', mailTo + "");
    const res = await this.getCustomApi('SendMailPass', params);
    if (res.isSuccess) { }
    return res;
  }

  async register(model: RegisterModel) {
    const res = await this.postCustomApi('Register', model);
    return res;
  }

}
